.claim-balance-btn{
    border-radius: var(--radius);
    padding: 10px 20px;
    display: flex;
    width: fit-content;
    height: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
    font-weight: 700;
    color: white;
    box-shadow: 0px 0px 5px #1db5ff;
    background: linear-gradient(84.52deg, #1eb6ff 35.3%, #00ffff 100%);
    text-transform: capitalize;
}
.claim-balance-btn.disabled{
    opacity: 0.5;
}
.claim-balance-btn p{
    font-weight: 700;
}