.progress_bar_container {
	position: relative;
	width: 100%;
	background-color: #e0e0e0;
	border-radius: var(--radius);
	overflow: hidden;
	display: flex;
	margin-top: 70%;
}
.progress_bar_container p {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	font-weight: bold;
	color: var(--windowColor);
}
.progress_bar_container p:nth-child(1) {
	left: 10px;
}
.progress_bar_container p:nth-child(3) {
	right: 10px;
}
.progress_bar {
	height: 25px;
	background-color: #ff9900;
	width: 0;
	border-radius: var(--radius);
	transition: width 0.3s ease;
}
