.group.referrals {
	position: absolute;
	bottom: 12%;
	display: flex;
	z-index: 9999;
	justify-content: space-between;
	padding: 20px;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
}
.referral-list{
	display: flex;
	flex-direction: column;
	align-items: center;
	max-height: 500px;
	overflow-y: scroll;
}
.block.referrals {
	display: flex;
	flex-direction: column;
	gap: var(--gap);
	height: 65%;
	max-height: 500px;
	width: 100%;
	overflow-y: scroll;
	position: relative;
	padding-top: 40px;
}
.referral.counts{
	display: flex;
	justify-content: space-between;
	width:48%;
}


.block.referrals::-webkit-scrollbar {
	display: none;
}
.gray-btn .icon {
	height: 20px;
	width: 20px;
}
.gray-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: var(--gap);
	transition: transform .3s ease;
}
.gray-btn:active{
	transform: scale(0.9);
}
.referral-total-awards{
	display: flex;
	width: 90%;
	justify-content: center;
	gap: 50px;
	margin-bottom: 25px;
}
.referral-total-awards div{
	display: flex;
	align-items: center;
	gap: 10px;
	color: white;
}
.referral-total-awards div img{
	width: 30px;
}
.currency-type {
	position: absolute;
	top: 10px;
	display: flex;
	justify-content: space-between;
	width:45%;
	right: 10px;
}
.currency-type img {
	width: 20px;
}

#referral {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

#referral #ref-block {
	display: flex;
	gap: var(--gap);
	align-items: center;
	flex: 1;
}

