/* src/components/RobotPopup/RobotPopup.css */
.robot_popup_overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	height: 100%;
	z-index: 999;
}

.robot_popup_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background: var(--windowColor);
	padding: 20px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	text-align: center;
	z-index: 999;
	height: 50%;
	position: absolute;
	bottom: 0;
	width: 100%;
	animation: 0.5s ease fromBottom forwards;
}
.robot_popup_content img {
	width: 50%;
}
button {
	width: 80%;
}
@keyframes fromBottom {
	0% {
		bottom: -100%;
	}
	100% {
		bottom: 0;
	}
}
