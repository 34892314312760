/* coins */
.main_coins {
	position: relative;
	width: 50px;
	height: 50px;
	right: 20px;
}

.coin:nth-child(1) {
	z-index: 5;
	left: -20px;
}
.coin:nth-child(2) {
	z-index: 4;
	left: 0px;
}
.coin:nth-child(3) {
	z-index: 3;
	left: 20px;
}
.coin:nth-child(4) {
	z-index: 2;
	left: 40px;
}
.coin:nth-child(5) {
	z-index: 1;
	left: 60px;
}
.coin {
	position: absolute;
	height: auto;
	width: 100%;
}
.coin img {
	width: 100%;
	height: 100%;
}
.disabled {
	opacity: 0.5;
}
