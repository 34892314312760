.container {
	height: 100vh;
	width: 100vw;
	padding: 20px;
	text-align: center;
	background-color: var(--windowColor);
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 9999;
}
.coins {
	position: absolute !important;
	top: 20% !important;
}

.heading {
	position: absolute;
	font-size: 6vw;
	top: 10vw;
	font-weight: 700;
	background: var(--textGradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	color: transparent;
}

.rewardText {
	position: absolute;
	font-size: 5vw;
	left: 50%;
	transform: translateX(-50%);
	bottom: 50vw;

	color: white;
}
.progressContainer {
	position: absolute;
	bottom: 25vw;
	width: 90%;
	border-radius: var(--radius);
	height: 15px;
	background-color: #e0e0e0;
}
.years {
	position: absolute;
	font-size: 50vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.progressBar {
	height: 100%;
	background-color: #ff9900;
	border-radius: var(--radius);
	transition: width 1s ease-out;
}
.gradient_btn {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 5vw;
	border-radius: var(--radius);
	padding: var(--blockPadding) calc(var(--blockPadding) * 2);
	font-size: 24px;
	font-weight: 700;
	width: 90vw;
	color: white;
	box-shadow: 0px 0px 5px #1db5ff;
	background: linear-gradient(84.52deg, #1eb6ff 35.3%, #00ffff 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
}
