.container.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
#main-balance {
	position: relative;
	display: flex;
	align-items: center;
	gap: var(--gap);
}
#main-balance #count {
	font-size: 54px;
}
.tap-zone {
	cursor: pointer;
	width: 100%;
	height: 15rem;
	transition: all ease 0.05s;
}
.tap-zone::after {
	content: '';
	position: absolute;
	width: 50%;
	left: 50%;
	transform: translateX(-50%) scale(100%);
	height: 10px;
	bottom: 10px;
	background-color: #9a9a9a7e;
	border-radius: 50%;
	filter: blur(10px);
}
.tap-zone img {
	height: 100%;
	width: 100%;
}
.group.main {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.group.main .energy {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: var(--gap);
}
.progress-bar-container {
	width: 100%;
	background-color: #e0e0e0;
	border-radius: var(--radius);
	overflow: hidden;
	margin-top: 10px;
}

.progress-bar {
	height: 20px;
	background-color: #ff9900;
	width: 0;
	border-radius: var(--radius);
	transition: width 0.3s ease;
}
.tap_number {
	position: absolute;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 10vw;
	font-weight: 700;
	pointer-events: none;
	animation: flyUp 1s ease-out forwards;
	opacity: 1;
	background-color: transparent;
	z-index: 999;
	overflow: hidden;
}

@keyframes flyUp {
	0% {
		transform: translate(-50%, -50%) translateY(0);
		opacity: 1;
	}
	100% {
		transform: translate(-50%, -50%) translateY(-100px);
		opacity: 0;
	}
}
