.tasks-content {
	display: flex;
	flex-wrap: wrap;
	gap: var(--gap);
	overflow-x: auto;
	flex-direction: column;
	height: 80%;
	margin: auto auto;
}
.tasks-content::-webkit-scrollbar {
	display: none;
}
#task-container {
	display: flex;
	flex-direction: row;
	gap: var(--gap);
}

.block.tasks {
	display: flex;
	flex-direction: column;
	gap: var(--gap);
	height: fit-content;
	width: 100%;
}

.task {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

#task-block {
	display: flex;
	gap: var(--gap);
}

#task-info {
	display: flex;
	flex-direction: column;
	gap: var(--gap);
}
#task-info p {
	text-wrap: nowrap;
}
#task-info p:nth-child(1) {
	color: #ff9900;
}
.task-logo {
	width: 50px;
	height: 50px;
	min-height: 50px;
	min-width: 50px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: white;
}
.task-logo img {
	width: 100%;
}
.ad-container {
	display: flex;
	align-items: center;
	gap: 10px;
	height: 35px;
	position: absolute;
	width: 90vw;
	bottom: 15%;
	.ad-btn {
		background: linear-gradient(45deg, #ffa500, #ffd700);
		color: #544617;
		font-size: 16px;
		font-weight: bold;
		padding: 12px 24px;
		height: 100%;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40%;
		border: none;
		cursor: pointer;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
		transition: transform 0.3s ease, box-shadow 0.3s ease;
		&:disabled {
			pointer-events: none;
		}
	}

	.ad-btn:hover {
		box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
	}
	.ad-btn:active {
		transform: scale(1.05);
	}
}
