.set_currency {
	display: flex;
	height: 75px;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.set_currency img {
	width: 100%;
}
.set_currency input {
	display: none;
}
.set_currency label {
	width: 50px;
	height: 50px;
	opacity: 0.5;
	transition: all 0.3s ease;
	border-radius: 50%;
	border: 3px solid transparent;
}
.set_currency input:checked + label {
	opacity: 1;
	border: 3px solid yellow;
}
