.progress_bar_container {
	width: 100%;
	background-color: #e0e0e0;
	overflow: hidden;
	position: absolute;
	bottom: 0;
}

.progress_bar {
	height: 20px;
	background-color: #ff9900;
	width: 0;
	transition: 0.5s all linear;
}
